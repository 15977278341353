import React, { useRef, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

import PageWidth from "./elements/PageWidth";
import LoadingError from "./elements/LoadingError";
import Image from "./elements/Image";

import { ReactComponent as Phone } from "../static/images/phone.svg";
import { ReactComponent as Mail } from "../static/images/envelope.svg";

const HEADER_QUERY = gql`
  query HeaderQuery {
    allSettings {
      contactEmail: headlessWpSettingsContactEmail
      phoneNumber: headlessWpSettingsPhoneNumber
    }
  }
`;

const Header = ({ sticky }) => {
  const { error, data } = useQuery(HEADER_QUERY);
  const headerRef = useRef();
  const spacerRef = useRef();

  useEffect(() => {
    if (sticky && spacerRef.current) {
      spacerRef.current.style.height = `${headerRef?.current?.clientHeight}px`;
    }
  }, [sticky]);

  if (error) return <LoadingError error={error.message} />;

  return (
    <header id="header">
      <div
        ref={headerRef}
        className={`w-100 z-2 ${
          sticky ? "absolute fixed-l top-0" : "relative"
        }`}
      >
        <div className="bg-near-black gray pb3 pb0-l">
          <nav>
            <PageWidth className="dt-l">
              <div className="brand flex-l items-center-l tc dtc-l v-mid-l tl-l">
                <Link to="/" className="dib border-box mv3">
                  <Image src="/images/logo.png" webp="/images/logo.webp" />
                </Link>
              </div>
              <div className="db tc dtc-l v-mid-l tr-l f7">
                {data?.allSettings?.phoneNumber && (
                  <div className="dib mr3">
                    <a
                      className="gray"
                      href={`tel:+1${data.allSettings.phoneNumber}`}
                    >
                      <Phone className="w1 v-mid" />{" "}
                      {data.allSettings.phoneNumber}
                    </a>
                  </div>
                )}

                {data?.allSettings?.contactEmail && (
                  <div className="dib">
                    <a
                      className="gray"
                      href={`mailto:${data.allSettings.contactEmail}`}
                    >
                      <Mail className="w1 v-mid" />{" "}
                      {data.allSettings.contactEmail}
                    </a>
                  </div>
                )}
              </div>
            </PageWidth>
          </nav>
        </div>
      </div>

      {sticky && <div ref={spacerRef} />}
    </header>
  );
};

export default () => (
  <Switch>
    <Route
      path="/"
      exact
      render={(props) => <Header {...props} sticky={true} />}
    />
    <Route render={(props) => <Header {...props} sticky={false} />} />
  </Switch>
);

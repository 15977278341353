import React from 'react';
import { gql, useQuery } from '@apollo/client';

import LoadingError from '../elements/LoadingError';
import PostContent from '../elements/PostContent';
import PageWidth from '../elements/PageWidth';

const QUERY = gql`
  query whoisbruce {
    pageBy(uri: "home/ramblings-of-the-bruce") {
      databaseId
      slug
      title
      content
    }
  }
`;

export default props => {
  const { error, data } = useQuery(QUERY);

  if (error) return <LoadingError error={error.message} />;

  return (
    <PageWidth { ...props } >
      <div className="f3 f1-l tc mb3">{data?.pageBy?.title}</div>
      <PostContent className="mt3 tj tl-l" content={data?.pageBy?.content} />
    </PageWidth>
  );
}
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { BlocksThree } from "./elements/Blocks";
import Loading from "./elements/Loading";
import LoadingError from "./elements/LoadingError";
import Image from "./elements/Image";

import { ReactComponent as FacebookIcon } from "../static/images/facebook.svg";
import { ReactComponent as LinkedinIcon } from "../static/images/linkedin.svg";

const FOOTER_QUERY = gql`
  query SettingsQuery {
    allSettings {
      generalSettingsDescription
      generalSettingsTitle
      email: headlessWpSettingsContactEmail
      phone: headlessWpSettingsPhoneNumber
    }
  }
`;

const fixString = (str) => {
  if (str) {
    return str
      .split("...")
      .map(
        (i, index) =>
          `<nobr>${i}${index < str.split("...").length - 1 ? "..." : ""}</nobr>`
      )
      .join("");
  }

  return str;
};

const Footer = ({ settings, title, desc }) => (
  <footer id="footer" className="footer bg-near-black light-silver">
    <BlocksThree
      className="footer--inner f7-l f6"
      left={
        <div className="footer--left">
          <div className="brand mb3">
            <Link to="/" className="dib border-box">
              <Image src="/images/logo.png" webp="/images/logo.webp" />
            </Link>
          </div>

          <hr className="w2 ml0 b--red bw2 bt-0 bl-0 br-0 mb3" />

          <div
            className="desc mv3"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: fixString(desc) }}
          />

          {settings?.phone && (
            <div className="phone mv4">
              <strong>Phone -</strong> {settings.phone}
            </div>
          )}

          {settings?.email && (
            <div className="email mt4">
              <strong>Email - </strong>
              <a href={`mailto:${settings.email}`}>{settings.email}</a>
            </div>
          )}
        </div>
      }
      middle={<div />}
      right={
        <div className="footer--time-schedule">
          <div className="b f4 mb3">Follow "The Bruce"</div>
          <hr className="w2 ml0 b--red bw2 bt-0 bl-0 br-0 mb3" />
          <div className="mt2">
            <a
              className="mr3 dib"
              aria-label="Facebook"
              href="https://www.facebook.com/bruce.rosenblat?fref=ts"
              rel="nofollow noopen"
              target="_new"
            >
              <FacebookIcon className="w2 h2" />
            </a>
            <a
              className="mr3 dib"
              aria-label="LinkedIn"
              href="https://www.linkedin.com/pub/bruce-a-rosenblat/7/566/27b"
              rel="nofollow noopen"
              target="_new"
            >
              <LinkedinIcon className="w2 h2" />
            </a>
          </div>
        </div>
      }
    />
    <div className="copyright bg-black gray f7 tc pv4">
      <p>
        Copyright &copy; {new Date().getFullYear()} &bull; {title}. All Rights
        Reserved
      </p>
    </div>
  </footer>
);

export default () => {
  const { loading, error, data } = useQuery(FOOTER_QUERY);

  if (loading) return <Footer loading={true} title={<Loading />} />;
  if (error) return <LoadingError error={error.message} />;

  return (
    <Footer
      settings={data?.headlessWpSettings?.allSettings}
      title={data?.allSettings?.generalSettingsTitle}
      desc={data?.allSettings?.generalSettingsDescription}
    />
  );
};

import React from "react";
import { Helmet } from "react-helmet";
import LazyLoad from "react-lazy-load";
import { gql, useQuery } from "@apollo/client";

import Hero from "./elements/Hero";
import { BlocksTwoFull } from "./elements/Blocks";
import Image from "./elements/Image";
import LeadForm from "./elements/LeadForm";
import PostContent from "./elements/PostContent";
import Button from "./elements/Button";
import Loading from "./elements/Loading";
import LoadingError from "./elements/LoadingError";
import Animate from "./elements/Animate";

import WhoIsTheBruce from "./posts/WhoIsTheBruce";
import Coaching from "./posts/Coaching";
import Specialty from "./posts/Specialty";
import Ramblings from "./posts/Ramblings";
import GreatDeal from "./posts/GreatDeal";
import Testimonials from "./posts/Testimonials";
import TallCards from "./posts/TallCards";

const HOME_QUERY = gql`
  query HomeQuery {
    allSettings {
      generalSettingsDescription
    }
    frontPage {
      id
      title
      content
      fields {
        heroBanner {
          sourceUrl(size: LARGE)
        }
        heading
        subheading
      }
      seo {
        title
        metaDesc
      }
    }
  }
`;

const OnQueryFinished = ({ data: { frontPage, allSettings } }) => (
  <>
    {frontPage?.seo && (
      <Helmet>
        <title>{frontPage.seo.title}</title>
        <meta name="description" content={frontPage.seo.metaDesc} />
      </Helmet>
    )}

    <div className="home--content mb0">
      <Hero
        background={frontPage?.fields?.heroBanner?.sourceUrl}
        mainline={allSettings?.generalSettingsDescription}
        heading={frontPage?.fields?.heading}
        subheading={frontPage?.fields?.subheading}
      />

      <LazyLoad>
        <BlocksTwoFull
          className="mb5-l mb3"
          flexClassName="items-stretch-l"
          left={
            <div
              className="absolute-l absolute--fill-l near-white pa3 pa0-l f5 f4-l"
              style={{ backgroundColor: "#464742" }}
            >
              <div className="flex-l h-100-l items-center-l">
                <Animate className="w-70-l center-l" animate="slideInLeft slow">
                  <PostContent
                    className="mb4 tj tl-l"
                    content={frontPage?.content}
                  />

                  <Button
                    className="mr3 tc"
                    scrollTo="contact-us"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Make an Appointment
                  </Button>
                </Animate>
              </div>
            </div>
          }
          right={
            <div className="relative overflow-hidden w-100 h-100">
              <Image
                src="/images/bruce-rosenblat.jpg"
                className="grow center db o-0-l w-100 w-auto-l"
              />
              <Image
                src="/images/bruce-rosenblat.jpg"
                className="grow dn db-l absolute absolute--fill w-100 h-auto"
              />
            </div>
          }
        />
      </LazyLoad>

      <LazyLoad>
        <WhoIsTheBruce className="mv6-l mv3" />
      </LazyLoad>

      <LazyLoad>
        <BlocksTwoFull
          flexClassName="items-stretch-l"
          left={<GreatDeal />}
          right={
            <div className="bg-dark-gray h-100-l flex-l items-center">
              <Animate className="mw5 center" animate="tada delay-1s">
                <a
                  className="pv3 pv0-l db"
                  href="http://www.amazon.com/gp/product/1448988209/sr=1-2-catcorr/qid=1422997951/ref=olp_product_details?ie=UTF8&me=&qid=1422997951&sr=1-2-catcorr"
                >
                  <Image
                    src="/images/cover.jpg"
                    webp="/images/cover.webp"
                    className="center db"
                    style={{ maxHeight: "27rem" }}
                    alt="It's a Great Deal, All Three of Me Think So Book cover"
                  />
                </a>
              </Animate>
            </div>
          }
        />
      </LazyLoad>

      <LazyLoad>
        <Coaching className="mv6-l mv3" />
      </LazyLoad>

      <hr className="w2 ml0 b--dark-red bw2 bt-0 bl-0 br-0 mb3 center" />

      <LazyLoad>
        <Specialty className="mv6-l mv3" />
      </LazyLoad>

      <LazyLoad>
        <Testimonials className="mv6-l mv3" />
      </LazyLoad>

      <LazyLoad>
        <div className="mv6-l mv3">
          <Ramblings className="mb3" />
          <TallCards />
        </div>
      </LazyLoad>

      <div id="contact-us" className="bg-dark-gray pv5">
        <Animate className="mw6 center" animate="bounceIn delay-500ms">
          <LeadForm className="bg-white pa4 ma3 ma0-l" />
        </Animate>
      </div>
    </div>
  </>
);

export default () => {
  const { loading, error, data } = useQuery(HOME_QUERY);

  if (loading) return <Loading className="center db" />;
  if (error) return <LoadingError error={error.message} />;

  return <OnQueryFinished data={data} />;
};

import React from 'react';
import { Link } from 'react-router-dom';

export const PrimaryClasses = 'pointer link bg-animate hover-bg-blue br2 ph4 pv2 w-100 w-auto-l db tc tl-l dib-l white bg-red bn';
export const SecondaryClasses = 'pointer link dim br2 ph4 pv2 w-100 w-auto-l db tc tl-l dib-l white ba b--white';
export const TertiaryClasses = 'pointer link dim br2 ph4 pv2 w-100 w-auto-l db tc tl-l dib-l red ba b--red';

const scroll = id => {
  let el = document.getElementById(id);

  if (el) {
    let bb = el.getBoundingClientRect();
    window.scrollTo({top: bb.bottom, left: 0, behavior: 'smooth' });
  }
}

export default ({ children, className, type, scrollTo, ...rest }) => {
  let classNames = PrimaryClasses;

  if (2 === type) {
    classNames = SecondaryClasses;
  }

  if (3 === type) {
    classNames = TertiaryClasses;
  }

  if (rest.href) {
    return (
      <a className={`${classNames} ${className || ''}`} { ...rest }>
        {children}
      </a>
    );
  }

  if (rest.to) {
    return (
      <Link className={`${classNames} ${className || ''}`} { ...rest }>
        {children}
      </Link>
    );
  }

  if (scrollTo) {
    return (
      <div className={`${classNames} ${className || ''}`} onClick={() => {scroll(scrollTo)}} { ...rest }>
        {children}
      </div>
    );
  }

  return (
    <div className={`${classNames} ${className || ''}`} { ...rest }>
      {children}
    </div>
  );
}
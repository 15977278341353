import React from 'react';
import { gql, useQuery } from '@apollo/client';

import LoadingError from '../elements/LoadingError';
import PostContent from '../elements/PostContent';

const QUERY = gql`
  query whoisbruce {
    pageBy(uri: "home/its-a-great-deal") {
      databaseId
      slug
      title
      content
    }
  }
`;

export default ({ className, ...props }) => {
  const { error, data } = useQuery(QUERY);

  if (error) return <LoadingError error={error.message} />;

  return (
    <div className={`bg-silver black pv4 ${className || ''}`} { ...props } >
      <div className="mw6 center ph3 ph0-l">
        <div className="f3 f1-l tc mb3">{data?.pageBy?.title}</div>
        <PostContent className="mt3 tj tl-l" content={data?.pageBy?.content} />
      </div>
    </div>
  );
}
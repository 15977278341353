import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Slide } from 'react-slideshow-image';

import LoadingError from '../elements/LoadingError';
import PostContent from '../elements/PostContent';
import PageWidth from '../elements/PageWidth';

import { ReactComponent as Left } from '../../static/images/chevron-left.svg';
import { ReactComponent as Right } from '../../static/images/chevron-right.svg';
import { isMobile } from '../utils/Browser';

const QUERY = gql`
  query TestimonialQuery {
    testimonials(
      first: 5
      where: {status: PUBLISH, hasPassword: false}
    ) {
      edges {
        node {
          id
          title
          content
          fields {
            author
            title
          }
        }
      }
    }
  }
`;

const properties = {
  className: 'mb4',
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: i => (
    <div className="indicators br-100 bg-mid-gray h1 w1 mh1 pointer" />
  ),
  prevArrow: (
    <div className="pointer"><Left width={24} /></div>
  ),
  nextArrow: (
    <div className="pointer"><Right width={24} /></div>
  ),
  arrows: true,
  pauseOnHover: true,
}

const mobileOverrides = {
  arrows: false,
  pauseOnHover: false,
  indicators: false,
}

export default () => {
  const { error, data } = useQuery(QUERY);

  if (error) return <LoadingError error={error.message} />

  const p = isMobile() ? { ...properties, ...mobileOverrides } : properties;

  return (
    <div className="testimonials pv5 bg-moon-gray dark-gray tc relative">
      <PageWidth>
        {data?.testimonials?.edges?.length > 0 && (
          <Slide {  ...p }>
            {data.testimonials.edges.map(testimonial => (
              <div key={testimonial.node.id} className="each-slide flex-l justify-center-l h-100-l flex-column-l ph3 ph5-l">
                <PostContent className="f6 f4-l fw3 lh-copy" content={testimonial.node.content} />
                <div className="mb2 fw7">{testimonial.node.fields.author}</div>
                <div className="i f7">{testimonial.node.fields.title}</div>
              </div>
            ))}
          </Slide>
        )}
      </PageWidth>
    </div>
  );
}
import React from 'react';

import Button from './Button';
import Animate from './Animate';
import { isWebpSupported } from '../utils/Browser';

export const HeroSkeleton = ({ className, ...props }) => (
  <div className={`hero bg-light-gray bg-left bg-center-l relative z-1 overflow-hidden ${className || ''}`} { ...props } >
    <div className="tc-l mt4 mt5-m mt6-l ph3">
      <div className="hero--inner relative z-1 pv6">
          <div className="f2 f1-l fw2 white-90 mb0 mt4 h2 w-20 center loading-block" />
          <div className="fw1 f3 white-80 mt3 mb4 h1 w-40 center loading-block" />

          <Button className="v-mid h2" />
      </div>
    </div>
  </div>
);

const fixString = str => {
  if (str) {
    return str.split('...').map((i, index) => `<nobr>${i}${index < str.split('...').length - 1 ? '...' : ''}</nobr>`).join('');
  }

  return str;
};

export default ({ className, heading, subheading, mainline, background, backgroundWebp }) => (
  <div className={`hero cover bg-left bg-center-l relative z-1 overflow-hidden ${className || ''}`} style={{backgroundImage: `url(${isWebpSupported() && backgroundWebp ? backgroundWebp : background })`}}>
    <div className="flex items-center tc">
      <Animate animate="fadeIn slower" className="hero--inner w-100 relative z-1 pa2 pa0-l w-50-l center">
        {mainline && (
          <div
            className="f3 f2-l fw4 i white mb3 lh-title text-shadow"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: fixString(mainline) }}
          />
        )}
        {heading && (
          <h1 className="f5 f3-l fw4 i white ma0 lh-title text-shadow">{heading}</h1>
        )}
        {subheading && (
          <h2 className="fw7 f5 f3-l white mt3 mb0 text-shadow">{subheading}</h2>
        )}
      </Animate>
    </div>

    <div className="bg-black o-50 absolute absolute--fill z-0" />
  </div>
);
